import { h } from "@stencil/core";
import { tagOf } from "../../classes/misc-util";
export const SkeletonAware = (props, content) => {
    var _a, _b, _c;
    return (h("div", { hidden: (_a = props.hideSlot) !== null && _a !== void 0 ? _a : true, style: { display: ((_b = props.hideSlot) !== null && _b !== void 0 ? _b : true) ? "none" : "contents" } }, h("slot", { name: (_c = props.slotName) !== null && _c !== void 0 ? _c : "skeleton", onSlotchange: (e) => {
            const slotElement = e.target;
            if (!slotElement.isConnected)
                return;
            const assignedElements = e.target.assignedElements({ flatten: true });
            const isSkeletonElement = (element) => tagOf(element) === "dx-skeleton";
            const isSkeletonSlotted = assignedElements.some((element) => {
                if (isSkeletonElement(element)) {
                    return true;
                }
                return element.querySelector("dx-skeleton") != null;
            });
            props.onSkeletonSlotted(isSkeletonSlotted, slotElement);
        } }, content)));
};
